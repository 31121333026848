import React, { useEffect, useState } from 'react';
import { Fab, Box, Button } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Typography500 from 'views/common/components/UI/Typography500';
import { useSelector } from 'react-redux';

const scaleToZero = keyframes`
0% {
  transform: scaleX(1);
}
70% {
  transform: scaleX(1);
  border-radius: 20px 8px 8px 20px;
  opacity:1;
}
100% {
  transform: scaleX(0);
  border-radius: 20px 8px 8px 20px;
  opacity:0;
  display: none;
}
`;
const scaleToOne = keyframes`
0% {
  transform: scaleX(0);
  border-radius: 20px 8px 8px 20px;
  opacity:0;
}
50% {
  transform: scaleX(0);
  border-radius: 20px 8px 8px 20px;
  opacity:1;
}
100% {
  transform: scaleX(1);
  border-radius: 8px;
  opacity:1;
}
`;
const StyledButton = styled(Button)(({ theme, scroll, stopAnimations }) => ({
  '&.reservationFloatingButton': {
    position: scroll ? 'fixed' : 'relative',
    right: scroll && theme.spacing(8),
    top: scroll && theme.spacing(4),
    zIndex: scroll ? 1199 : 'unset',
    [theme.breakpoints.down('lg')]: {
      position: 'fixed',
      top: 'unset',
      bottom: theme.spacing(4),
      right: 3,
      zIndex: 1199,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover .reservationLable': {
      transition: 'transform  1s ',
      animation: !stopAnimations && `${scaleToOne} 1.5s`,
      transform: 'scaleX(1)',
    },
    '&:active .RFBPlusButton': {
      color: theme.palette.primary.main,
      transition: 'transform  .1s ',
      transform: 'Scale(1.5)',
    },
    '&:not(:active) .RFBPlusButton': {
      transition: 'transform  1s ',
    },
    '& .reservationLable': {
      position: 'relative',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      left: theme.spacing(3.5),
      top: theme.spacing(0),
      width: theme.spacing(35.375),
      height: theme.spacing(7),
      lineheight: theme.spacing(3),
      borderRadius: scroll ? theme.spacing(0.5) : theme.spacing(0.5),
      backgroundColor: theme.palette.primary.dark,
      transform: scroll ? 'scaleX(0)' : 'scaleX(1)',
      transformOrigin: '100% 100%',
      transition: 'transform  1s ',
      whiteSpace: 'pre',
      animation:
        !stopAnimations
        && (scroll ? `${scaleToZero} 1.5s` : `${scaleToOne} 1.5s`),
      [theme.breakpoints.down('lg')]: {
        animation: !stopAnimations && `${scaleToZero} 1.5s`,
        transform: 'scaleX(0)',
      },
      '& .reservationText': {
        color: theme.palette.primary.contrastText,
        fontSize: theme.spacing(2.25),
        lineheight: theme.spacing(2),
      },
    },
    '& .RFBPlusButton': {
      height: theme.spacing(7),
      width: theme.spacing(7),
      transition: 'width 0.6s linear',
      boxShadow: 'unset',
      zIndex: scroll ? 1199 : 'unset',
      '&:hover': {
        boxShadow:
          '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
      },
    },
  },
  '&.staticReservationFloatingButton': {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .reservationLable': {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(1.1065, 3.875, 1.1065, 2.028),
      lineHeight: theme.spacing(2.2125),
      borderRadius: theme.spacing(0.5),
      color: theme.palette.common.fogWhite,
      backgroundColor: theme.palette.primary.dark,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '& .reservationText': {
        fontSize: theme.typography.pxToRem(13.279),
        lineHeight: theme.typography.pxToRem(17.7),
      },
    },
    '& .RFBPlusButton': {
      position: 'relative',
      right: theme.spacing(3),
      top: theme.spacing(0),
      width: theme.spacing(5.25),
      height: theme.spacing(5.25),
      boxShadow: '0px 0px 8.11475px rgba(0, 0, 0, 0.14)',
    },
  },
}));
const ReservationFloatingButton = (props) => {
  const {
    className,
    text,
    handleClick,
    scrollValue = 130,
    staticButton = false,
  } = props;
  const [scroll, setScroll] = useState(0);
  const isCrawler = useSelector((state) => state.globalData.isCrawler);
  useEffect(() => {
    const onScroll = () => {
      const scrollCheck = window.scrollY > scrollValue;
      setScroll(scrollCheck);
    };
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [scroll, setScroll]);
  return (
    <StyledButton
      id="floatingButton"
      className={`${
        staticButton
          ? 'staticReservationFloatingButton'
          : 'reservationFloatingButton'
      } ${className}`}
      scroll={scroll}
      disableRipple
      stopAnimations={isCrawler}
      onClick={handleClick}>
      <Box className="reservationLable">
        <Typography500 className="reservationText">{text}</Typography500>
      </Box>
      <Fab color="primary" aria-label="add" className="RFBPlusButton">
        <AddIcon />
      </Fab>
    </StyledButton>
  );
};
export { ReservationFloatingButton };
